import * as React from "react"
import { Link } from "gatsby"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/financial-services/",
    label: "FINANCIAL SERVICES",
  },
]

const resourcesList = [
  {
    title: "Penetration Testing",
    subtitle: "",
    LinkUr: "https://sp1.sdcdn.app/pdf/Pen-Test-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMVDR",
    subtitle: " Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MVDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="FINANCIAL SERVICES" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-2">
      <section className="section-title-top--platform">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>FINANCIAL SERVICES</h1>
              <h4>SECURITY AND COMPLIANCE</h4>
              <p className="content">
                Our Tailored Tradecraft Provides Layered Protection
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-copy-img-r about-us--hero-img-fs">
      <div className="container">
        <div className="row">
          <div className="section-col-1 desk-only">
            <div className="side-binder about-us--gives">
              <h3>
                PROTECTING THE <br /> MOST TARGETED <br /> INDUSTRY
              </h3>
              <h6>
                REGULATORY COMPLIANCE. <br /> CUSTOMER PROTECTION.
              </h6>
            </div>
          </div>
          <div className="section-col-2">
            <div className="side-binder about-us--gives mob-only">
              <h1>OUR MISSION</h1>
              <h3>TO BE VIGILANT</h3>
            </div>
            <p>
              August 2021: $600 Million stolen in a cyber currency heist. While
              much of the stolen currency was returned in time, it is impossible
              to assess the damage to consumer confidence and the cost of
              transaction interruptions.
            </p>
            <p>
              Financial Services is the #1 most targeted industry. From
              ransomware attacks to customer data exfiltration schemes, Vigilant
              provides integrated 24/7/365 monitoring, attack surface reduction
              and maximum visibility over a finance company’s entire cyber
              landscape – virtual, on-premises or in the Cloud.
            </p>
            <p>
              Vigilant provides financial regulatory compliance, assisting in
              key NIST, PCI, and FFIEC requirements, including client data
              access, malware defense and vulnerability monitoring.
              Additionally, our CyberDNA® Command and Control Center provides
              maximum endpoint visibility far beyond compliance, providing a
              streamlined collaborative system with virtually no drag on ongoing
              financial services.
            </p>

            <p>
              What are your needs? Vigilant’s MDR Services Model is nimble and
              customizable. Do you need every day “eyes on” Sentinels to
              tighten-up the gaps within your existing technologies? We can
              help. Do you need to power-up your offense? Vigilant has peerless
              threat hunting, penetration testing, and adversary simulation.
              Bottom Line: We are here for you.
            </p>
            <p>
              Vigilant exists to protect you, your customers, and your company’s
              assets.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div className="grid--bg --for-it-ops-1--center">
      <section className="section-client-quote">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h3>
                  “Vigilant identified possible exploits on our Microsoft
                  Exchange server and quickly remediated and monitored the
                  situation. Other companies would have taken advantage of the
                  situation and performed the same work for a large lump sum of
                  money.”
                </h3>
                <hr />
                <h6>- CLIENT IN FINANCIAL SERVICES INDUSTRY</h6>
                <p className="pBlue">
                  (For security reasons, Vigilant never reveals the identity of
                  our clients)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
